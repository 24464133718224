import {
    Badge,
    BadgeTextField,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Modal,
    Select,
    Table,
    TablePagination,
    TextField,
} from "@/components";
import PdfViewer from "@/components/pdf/PDFviewer";
import { finishLoading, startLoading } from "@/config/slices";
import { useAppDispatch } from "@/hooks";
import {
    AppointmentListHistory,
    AppointmentListHistoryResponse,
    DownloadHistory,
    ParamsListAppHistory,
    ParamsOriginSegmentHistory,
    PatientList,
} from "@/models/generalHistory/generalHistory";
import { AttentionAdxSection } from "@/models/history";
import { CommonSelect, Squints } from "@/models/sheets/orthoptic";
import { getSquintsDegreeService, getSquintsService, getVersionsList } from "@/services";
import { formatteDocument } from "@/utils";
import {
    IconCircleCheck,
    IconDotsVertical,
    IconExclamationCircle,
    IconEye,
    IconPlus,
    IconPrinter,
    IconSearch,
    IconSettings,
} from "@tabler/icons-react";
import { Suspense, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import DiagnosticAdxDetails from "../PatientAttention/Consultation/History/components/Details/DiagnosticAdxDetails";
import SurgeryDownloadFile from "../PatientAttention/Consultation/History/components/Details/SurgeryDetails/SurgeryDownloadFile";
import HistoryComponent from "../PatientAttention/Consultation/History/components/newHistory/HistoryComponent";
import ConfigSegments from "./ConfigSegments";
import DownloadHistoryFile from "./DownloadHistoryFile";
import "./GeneralHistory.scss";
import {
    getAccountsList,
    getDownloadHCX,
    getDownloadHistory,
    getDownloadHistoryPdf,
    getListAppointmentHistory,
    getOriginListSegment,
    getPatientList,
} from "./generalHistory.actions";

type HistoryTabs = "attention" | "diagnostic_aids" | "surgery" | "generals" | "custom";

const TAB_ITEMS = [
    { tag: "attention", label: "Consulta" },
    { tag: "diagnostic_aids", label: "ADX" },
    { tag: "surgery", label: "Cirugía" },
    { tag: "generals", label: "General" },
    { tag: "custom", label: "Personalizado" },
];

const GeneralHistory = () => {
    const dispatch = useAppDispatch();
    const printFile = useRef<HTMLTableElement>(null);
    const [patientSelected, setPatientSelected] = useState<PatientList | undefined>(undefined);
    const [selectedOrigin, setSelectedOrigin] = useState<{ label: string; value: string } | null>();
    const [patientListOptions, setPatientListOptions] = useState<PatientList[]>([]);
    const [appointmentList, setAppointmentList] = useState<AppointmentListHistoryResponse>();
    const [sheetSelected, setSheetSelected] = useState<{ value: string; label: string }[]>([]);
    const [originList, setOriginList] = useState<{ value: string; label: string }[]>([]);
    const [accountsList, setAccountsList] = useState<{ value: number; label: string }[]>([]);
    const [downloadDataSurgery, setDownloadDataSurgery] = useState<DownloadHistory[] | null>();
    const [downloadData, setDownloadData] = useState<DownloadHistory[] | undefined>();
    const [versionsState, setVersionsState] = useState<CommonSelect[]>([]);
    const [squints, setSquints] = useState<Squints[]>([]);
    const [degrees, setSquintsDegree] = useState<CommonSelect[]>([]);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [openVisualizer, setOpenVisualizer] = useState(false);
    const [hcxUrl, setHcxUrl] = useState("");
    const [configSegmentsModal, setConfigSegmentsModal] = useState<{
        isOpen: boolean;
        config: Record<string, boolean> | null;
    }>({ isOpen: false, config: null });

    const printAction = useReactToPrint({
        content: () => printFile.current,
    });
    const [filtersTable, setFiltersTable] = useState<ParamsListAppHistory>({
        page: 1,
        perpage: 10,
        prefixes: "",
    });

    const [search, setSearch] = useState("");
    const [triggerSearch, setTriggerSearch] = useState(0);
    const [filtersOrigin, setFiltersOrigin] = useState<ParamsOriginSegmentHistory>({
        show: 1,
        type: "sheet",
        modulePrefix: "attention",
    });
    
    const [currentTab, setCurrentTab] = useState<HistoryTabs | string>("attention");
    const [configModalADX, setConfigModalADX] = useState<{
        isOpenModal: boolean;
        appId?: number | null;
        tab?: string;
        attention?: AttentionAdxSection;
    }>({
        isOpenModal: false,
        appId: null,
        tab: "exam",
    });
    const [openDetailsModals, setOpenDetailsModals] = useState<{
        isOpenAttention: boolean;
        data?: AppointmentListHistory;
    }>({
        isOpenAttention: false,
    });

    const handleCloseModal = () => {
        setOpenDetailsModals({
            isOpenAttention: false,
        });
    };

    const handlePrint = async (sheet?: string) => {
        dispatch(startLoading());
        if (patientSelected?.id) {
            const sections = configSegmentsModal.config
                ? Object.entries(configSegmentsModal.config)
                    .filter(([, value]) => !!value)
                    .map(([key]) => key)
                    .join(",")
                : undefined;

            const response = await dispatch(
                getDownloadHistory({
                    cluId: patientSelected?.id,
                    modulePrefix: filtersTable?.modulePrefix === "custom" ? "attention" : filtersTable.modulePrefix || "",
                    eaccount: filtersTable?.eaccount,
                    prefixes: filtersTable?.prefixes,
                    sections: sections,
                    endDate: filtersTable.endDate,
                    startDate: filtersTable?.startDate,
                })
            );

            if (response) {
                if (sheet === "surgery") {
                    setDownloadDataSurgery(response);
                    setDownloadData(undefined);
                } else {
                    setDownloadData(response);
                    setDownloadDataSurgery(null);
                }
                await new Promise((resolve) => setTimeout(resolve, 10000));
                printAction();
            }
        }
        dispatch(finishLoading());
    };

    const handlePrintPdf = async (sheet?: string) => {
        if (patientSelected?.id) {
            const sections = configSegmentsModal.config
                ? Object.entries(configSegmentsModal.config)
                    .filter(([, value]) => !!value)
                    .map(([key]) => key)
                    .join(",")
                : undefined;

            const response = await dispatch(
                getDownloadHistoryPdf({
                    cluId: patientSelected?.id,
                    modulePrefix: filtersTable?.modulePrefix === "custom" ? "attention" : filtersTable.modulePrefix || "",
                    eaccount: filtersTable?.eaccount,
                    prefixes: filtersTable?.prefixes,
                    sections: sections,
                    endDate: filtersTable.endDate,
                    startDate: filtersTable?.startDate,
                })
            );

            if (response) {
                const anchor = document.createElement("a");
                anchor.target = "_blank";
                anchor.href = `data:application/pdf;base64,${response}`;
                anchor.download = `historia_clinica_${patientSelected.document}`;
                anchor.rel = "noreferrer";
                anchor.click();

                anchor.remove();
            }
        }
    };


    const getDownloadHCXFunction = async () => {
        if (patientSelected?.id) {
            const response = await dispatch(
                getDownloadHCX({
                    cluId: patientSelected.id,
                    filename: `HC ESCANEADA - ${patientSelected?.docTypeDesc} ${patientSelected?.document} - ${patientSelected?.first_name} ${patientSelected?.other_names} ${patientSelected?.last_name} ${patientSelected?.second_surname}`,
                })
            );
            if (response?.success) {
                if (response.results?.key) {
                    // downloadFileAddName(response.results?.key, `${patientSelected.first_name}-HCX`);
                    setHcxUrl(response.results.key);
                    setOpenVisualizer(true);
                }
            }
        }
    };

    const handleNextPage = (value: number): void => {
        setFiltersTable({ ...filtersTable, page: value });
    };
    const handlePrevPage = (value: number): void => {
        setFiltersTable({ ...filtersTable, page: value });
    };
    const handleMaxPage = (value: number): void => {
        setFiltersTable({ ...filtersTable, page: value });
    };
    const handleMinPage = (value: number): void => {
        setFiltersTable({ ...filtersTable, page: value });
    };

    const onChangeTab = (tab: HistoryTabs | string) => {
        if (tab === "attention" || tab === "generals") {
            setFiltersOrigin((state) => ({
                ...state,
                modulePrefix: tab === "attention" ? "attention" : "",
                type: tab === "attention" ? "sheet" : "segment",
            }));
        }
        if (tab === "custom") {
            setFiltersOrigin((state) => ({ ...state, type: "sheet" }));
        }
        setCurrentTab(tab);
        setFiltersTable((state) => ({ ...state, modulePrefix: tab, prefixes: "", page: 1 }));
        setConfigSegmentsModal({ isOpen: false, config: null });
        setSheetSelected([]);
        setSelectedOrigin(null);
    };

    const handleAddFunction = () => {
        const tempArray = [...sheetSelected];
        if (tempArray?.length > 0 && selectedOrigin?.value) {
            if (!tempArray.some((x) => selectedOrigin?.value === x.value)) {
                const temp = [...tempArray.map((x) => x.value), selectedOrigin?.value];
                setFiltersTable({ ...filtersTable, prefixes: String(temp), page: 1 });
                setSheetSelected([...sheetSelected, selectedOrigin]);
                setSelectedOrigin(null);
            }
        } else {
            if (selectedOrigin?.value) {
                setSheetSelected([...sheetSelected, selectedOrigin]);
                setFiltersTable({ ...filtersTable, prefixes: selectedOrigin?.value, page: 1 });
                setSelectedOrigin(null);
            }
        }
    };

    const handleOnDeleteItem = (item: string | number) => {
        const tempArraySheet = sheetSelected.filter((x) => item !== x.value);
        setSheetSelected(tempArraySheet);
        setFiltersTable({ ...filtersTable, prefixes: String([...tempArraySheet.map((x) => x.value)]) });
    };

    const handlePatientFunction = async (data: PatientList) => {
        dispatch(startLoading());
        const response = await dispatch(
            getPatientList(
                {
                    orderByType: "",
                    page: 1,
                    perpage: 100,
                    docFilter: data.document,
                },
                "medical/patient/listPatient/"
            )
        );
        if (response && response.length > 0) {
            const newData = response.find((obj) => obj.id === data.id);
            setPatientSelected(newData ?? data);
        } else {
            setPatientSelected(data);
        }
        dispatch(finishLoading());
        setFiltersOrigin((state) => ({ ...state, modulePrefix: currentTab }));
        setFiltersTable((state) => ({
            ...state,
            page: 1,
            cluId: data?.id,
            eaccount: null,
            endDate: "",
            startDate: "",
            modulePrefix: currentTab,
            prefixes: "",
        }));
        setSheetSelected([]);
    };

    const onConfigCustomHistory = () => {
        setConfigSegmentsModal((state) => ({ ...state, isOpen: true }));
    };

    const openDetailModalFunction = (data: AppointmentListHistory) => {
        if (currentTab === "diagnostic_aids" || data.attentionInfo.prefix === "diagnosticAids") {
            setConfigModalADX({ ...configModalADX, isOpenModal: true, appId: data.appId, tab: "exam" });
            setOpenDetailsModals({
                isOpenAttention: false,
                data,
            });
        } else {
            setOpenDetailsModals({
                isOpenAttention: true,
                data,
            });
        }
    };

    useEffect(() => {
        async function fetchData() {
            dispatch(startLoading());
            const response = await dispatch(getPatientList({ orderByType: "", search, page: 1, perpage: 100 }));
            if (response) {
                setPatientListOptions(response);
            }
            dispatch(finishLoading());
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, triggerSearch]);

    useEffect(() => {
        async function fetchData() {
            const responseAccounts = await getAccountsList();
            if (responseAccounts && responseAccounts?.length > 0) {
                const formatted = responseAccounts.map((account) => ({
                    value: account.id,
                    label: account.name,
                }));
                setAccountsList(formatted);
            }
        }
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        async function fetchData() {
            const data = await getVersionsList();
            if (data) {
                setVersionsState(data.results);
            }
            const dataS = await getSquintsService();
            setSquints(dataS.results);

            const dataSD = await getSquintsDegreeService();
            setSquintsDegree(dataSD.results);
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (currentTab === "attention" || currentTab === "generals" || currentTab === "custom") {
                const response = await getOriginListSegment(filtersOrigin);
                if (response) {
                    const formatted = response.map((origin) => ({
                        value: origin.prefix,
                        label: origin.name,
                    }));
                    setOriginList(formatted);
                }
            }
        }
        fetchData();
    }, [filtersOrigin, currentTab]);

    useEffect(() => {
        async function fetchData() {
            if (filtersTable.cluId && filtersTable.modulePrefix) {
                const response = await dispatch(getListAppointmentHistory(filtersTable));
                if (response) {
                    setAppointmentList(response);
                }
            }
        }
        fetchData();
    }, [filtersTable, dispatch, filtersTable.eaccount, filtersTable.endDate, filtersTable.startDate]);

    useEffect(() => {
        if (patientSelected !== undefined) {
            setSearch("");
            setTextFieldValue("");
        }
    }, [patientSelected]);

    const infoPatientRender = () => {
        return (
            <div className="col-3 ">
                <div className=" bodyInfo">
                    <h2 className="text-secondary text-center fw-bold">Búsqueda de paciente</h2>
                    <div className="d-flex flex-column px-4 mt-4 ">
                        <div className="py-3 cardSearch">
                            <span className="fw-bold text-primary px-2"> Buscar</span>
                            <TextField
                                type="text"
                                value={textFieldValue}
                                placeholder="Escribir..."
                                onChange={({ target }) => {
                                    setSearch(target.value);
                                    setTextFieldValue(target.value);
                                    setCurrentTab("attention");
                                }}
                                endIcon={
                                    <div
                                        onClick={() => {
                                            setPatientSelected(undefined);
                                            setTriggerSearch(triggerSearch + 1);
                                        }}
                                        className="pointer"
                                    >
                                        <IconSearch />
                                    </div>
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setPatientSelected(undefined);
                                        setTriggerSearch(triggerSearch + 1);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="px-4 ">
                        <span className="text-muted ">Ingrese el número de documento o nombre del paciente</span>
                    </div>
                    {patientSelected !== undefined ? (
                        <div className="px-4 py-4 w-100">
                            <div className="py-2">
                                <label className="text-secondary">Nombres</label>
                                <input
                                    readOnly
                                    type="text"
                                    value={` ${patientSelected.first_name} ${patientSelected.other_names} `}
                                    className="form-control"
                                    placeholder=""
                                />
                            </div>
                            <div className="py-2">
                                <label className="text-secondary">Apellidos</label>
                                <input
                                    type="text"
                                    value={` ${patientSelected.last_name} ${patientSelected.second_surname} `}
                                    className="form-control"
                                    placeholder=""
                                    readOnly
                                />
                            </div>
                            <div className="py-2">
                                <label className="text-secondary">Documento</label>
                                <input
                                    type="text"
                                    value={` ${formatteDocument({ value: patientSelected.document }).format} `}
                                    className="form-control"
                                    placeholder=""
                                    readOnly
                                />
                            </div>
                            <div className="py-2">
                                <label className="text-secondary">Fecha de nacimiento</label>
                                <input
                                    type="text"
                                    value={` ${patientSelected.birth_date}`}
                                    className="form-control"
                                    placeholder=""
                                    readOnly
                                />
                            </div>

                            <div className="py-2">
                                <label className="text-secondary">Whatsapp</label>
                                <input
                                    type="text"
                                    value={` ${patientSelected.whatsapp}  `}
                                    className="form-control"
                                    placeholder=""
                                    readOnly
                                />
                            </div>
                            <div className="py-2">
                                <label className="text-secondary">Correo electrónico</label>
                                <input
                                    type="text"
                                    value={` ${patientSelected.email}  `}
                                    className="form-control"
                                    placeholder=""
                                    readOnly
                                />
                            </div>
                            <div className="py-2">
                                <label className="text-secondary">Ultima atención</label>
                                <input
                                    type="text"
                                    value={` ${patientSelected.last_attention ?? ""}  `}
                                    className="form-control"
                                    placeholder=""
                                    readOnly
                                />
                            </div>
                            <div className="d-flex justify-content-between align-items-center py-4">
                                <Badge
                                    color={patientSelected?.sigeVerified ? "success" : "secondary"}
                                    className="d-flex align-items-center justify-content-start"
                                    style={{ width: "fit-content" }}
                                >
                                    {patientSelected?.sigeVerified ? <IconCircleCheck width={18} /> : <IconExclamationCircle width={18} />}
                                    <span className="fw-bold fs-6 mx-2 text-muted">
                                        {patientSelected?.sigeVerified ? "HC verificada" : "HC sin verificar"}
                                    </span>
                                </Badge>
                                <Button
                                    variant="secondary"
                                    onClick={() => getDownloadHCXFunction()}
                                >
                                    {" "}
                                    HCX Escaneada
                                </Button>
                            </div>
                            {renderHCXModal()}
                        </div>
                    ) : (
                        <>
                            <>
                                <div className="px-4 py-4 w-100 patientList mt-4">
                                    {patientListOptions.length > 0 &&
                                        patientListOptions.map((patient) => {
                                            return (
                                                <>
                                                    <hr />
                                                    <div
                                                        className="py-2 pointer text-muted"
                                                        onClick={() => {
                                                            handlePatientFunction(patient);
                                                        }}
                                                    >
                                                        <div>
                                                            <b>
                                                                {patient.first_name} {patient.other_names} {patient.last_name}{" "}
                                                                {patient.second_surname}
                                                            </b>
                                                        </div>
                                                        <div>
                                                            {patient.docTypeDesc} {patient.document}
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                </div>
                                <div className="text-muted px-4">({patientListOptions.length}) resultados</div>
                            </>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const historyFiltersRender = () => {
        return (
            <div className="row d-flex px-4">
                <div className="py-3 col-3">
                    <span className="fw-bold text-secondary px-2"> Desde</span>
                    <input
                        type="date"
                        value={filtersTable.startDate}
                        className="form-control"
                        placeholder=""
                        onChange={({ target }) => {
                            setFiltersTable((state) => ({ ...state, startDate: target.value, endDate: "" }));
                        }}
                    />
                </div>
                <div className="py-3 col-3">
                    <span className="fw-bold text-secondary px-2"> Hasta</span>
                    <input
                        type="date"
                        disabled={!filtersTable.startDate}
                        value={filtersTable.endDate}
                        className="form-control"
                        placeholder=""
                        onChange={({ target }) => {
                            setFiltersTable((state) => ({ ...state, endDate: target.value }));
                        }}
                    />
                </div>
                <div className="py-3 col-3">
                    <span className="fw-bold text-secondary px-2"> Cuenta</span>
                    <Select
                        isCleanable
                        key={`${accountsList}`}
                        placeholder="Seleccionar..."
                        value={accountsList.find((x) => x.value === filtersTable.eaccount)}
                        isSearchable
                        options={accountsList}
                        onChange={({ option }) => {
                            setFiltersTable((state) => ({ ...state, eaccount: option.value }));
                        }}
                        name="account"
                    />
                </div>
            </div>
        );
    };

    const renderTabs = () => {
        return (
            <div className="px-4">
                <div className="d-flex ">
                    <div className="">
                        <ul className="nav nav-tabs">
                            {TAB_ITEMS.map((tab) => (
                                <li
                                    key={tab.tag}
                                    style={{ width: "180px" }}
                                    className="nav-item pointer"
                                    onClick={() => onChangeTab(tab.tag)}
                                >
                                    <div className={`nav-link text-center ${tab.tag === currentTab ? "active" : ""}`}>{tab.label}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const renderRow = (appointment: AppointmentListHistory) => {
        return (
            <tr key={appointment.appId}>
                <td className="text-center">
                    <span className="fw-bold text-center"> {appointment.createdAt.split(" ")[0]}</span>
                </td>

                <td
                    className="overflow-hidden text-ellipsis"
                    style={{ maxWidth: 130 }}
                    title={appointment.service}
                >
                    <span className="fw-bold">{appointment.service ?? "-"}</span>
                </td>

                <td className="text-center align-items-center justify-content-center nowrap align-self-center">
                    <div className="d-flex flex-column align align-items-center">
                        <Badge
                            style={{
                                backgroundColor: appointment.status.bgColor || "#E8E8EA",
                                color: appointment.status.fontColor || "#6E6F7C",
                            }}
                        >
                            {appointment.status.name}
                        </Badge>
                    </div>
                </td>
                <td align="right">
                    <Dropdown className="dropdown">
                        <IconDotsVertical
                            size={15}
                            className="pointer text-muted"
                            data-bs-toggle="dropdown"
                        />
                        <DropdownMenu>
                            <DropdownItem onClick={() => openDetailModalFunction(appointment)}>
                                <IconEye />
                                <span className="ms-2">Ver detalle</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <div className="">
                <Table>
                    <thead>
                        <tr>
                            <th className="text-center col-2">Fecha de atención</th>
                            <th className="col-6">Evento</th>
                            <th className="text-center col-3">Estado</th>
                            <th className=""></th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointmentList?.results?.length ? (
                            appointmentList?.results?.map((appointment) => renderRow(appointment))
                        ) : (
                            <tr>
                                <td
                                    colSpan={12}
                                    align="center"
                                    style={{ padding: "15px 0" }}
                                >
                                    No hay citas disponibles para las fechas seleccionada.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        );
    };

    const renderHCXModal = () => {
        return (
            <Modal
                isOpen={openVisualizer}
                onClose={() => setOpenVisualizer(false)}
                fullScreen
                className="d-flex justify-content-center "
            >
                <div className="w-100 h-100 py-5">
                    <PdfViewer pdfUrl={hcxUrl} />
                </div>
            </Modal>
        );
    };

    return (
        <div className="w-100 row g-0 h-100 overflow-auto">
            {infoPatientRender()}
            <div className="col-8 sectionBorder bodyInfo ">
                <h1 className="text-secondary fw-bold mb-2 display-5 px-4">Historial general</h1>
                {patientSelected !== undefined && (
                    <>
                        {historyFiltersRender()}
                        {renderTabs()}
                        {(currentTab === "attention" || currentTab === "generals") && (
                            <div className="px-4 py-2 ">
                                <div className=" d-flex ">
                                    <div className="col-3 d-flex">
                                        <div className="">
                                            <span className="fw-bold text-secondary px-2">Origen</span>
                                            <Select
                                                placeholder="Seleccionar..."
                                                isSearchable
                                                isCleanable
                                                value={selectedOrigin ? selectedOrigin : { value: "", label: "" }}
                                                options={originList}
                                                onChange={({ option }) => {
                                                    setSelectedOrigin(option);
                                                }}
                                                name="origin"
                                            />
                                        </div>
                                        <div className="d-flex align-self-center">
                                            {selectedOrigin && (
                                                <div
                                                    className="pointer mt-4"
                                                    onClick={() => {
                                                        handleAddFunction();
                                                    }}
                                                >
                                                    <IconPlus className="text-primary" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-8 align-self-end">
                                        {sheetSelected.length > 0 && (
                                            <div className="align-self-end px-2 overflow-hidden">
                                                <BadgeTextField
                                                    bookmarks={sheetSelected}
                                                    onDeleteItem={(item) => handleOnDeleteItem(item)}
                                                    isErasable
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-1 align-self-end">
                                        <div
                                            className="pointer"
                                            onClick={() => handlePrintPdf()}
                                        >
                                            <IconPrinter className="text-muted pointer ms-3" />
                                        </div>
                                    </div>
                                    {/* } */}
                                </div>
                            </div>
                        )}
                        {currentTab === "surgery" && (
                            <div className="col-12 py-2 d-flex justify-content-end px-4">
                                <div
                                    className="pointer px-4 mx-4"
                                    onClick={() => handlePrint("surgery")}
                                >
                                    <IconPrinter className="text-muted pointer ms-3" />
                                </div>
                            </div>
                        )}

                        {currentTab === "custom" && (
                            <div className="px-4 py-2">
                                <div className=" d-flex">
                                    <div className="col-3 d-flex">
                                        <div>
                                            <span className="fw-bold text-secondary px-2">Hoja</span>
                                            <Select
                                                placeholder="Seleccionar..."
                                                isSearchable
                                                isCleanable
                                                value={selectedOrigin ? selectedOrigin : { value: "", label: "" }}
                                                options={originList}
                                                onChange={({ option }) => {
                                                    setSelectedOrigin(option);
                                                }}
                                                name="sheet"
                                            />
                                        </div>
                                        <div className="d-flex align-self-center">
                                            {selectedOrigin && (
                                                <div
                                                    className="pointer mt-4"
                                                    onClick={() => {
                                                        handleAddFunction();
                                                    }}
                                                >
                                                    <IconPlus className="text-primary" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center col-8 align-self-end">
                                        {sheetSelected.length > 0 && (
                                            <>
                                                <div
                                                    className="pointer"
                                                    onClick={onConfigCustomHistory}
                                                >
                                                    <IconSettings className="text-primary" />
                                                </div>
                                                <div className="align-self-end px-2 overflow-hidden">
                                                    <BadgeTextField
                                                        bookmarks={sheetSelected}
                                                        onDeleteItem={(item) => handleOnDeleteItem(item)}
                                                        isErasable
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-1 align-self-end">
                                        <div
                                            className="pointer"
                                            onClick={() => handlePrintPdf()}
                                        >
                                            <IconPrinter className="text-muted pointer ms-3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="d-flex flex-column flex-grow-1 p-4">
                            {renderTable()}
                            <TablePagination
                                totalPages={appointmentList?.rowTotal as number}
                                perPage={10}
                                currentPage={filtersTable.page as number}
                                totalCount={appointmentList?.results?.length as number}
                                onNextPage={(value) => handleNextPage(value as number)}
                                onPrevPage={(value) => handlePrevPage(value as number)}
                                onMaxPage={(value) => handleMaxPage(value as number)}
                                onMinPage={(value) => handleMinPage(value as number)}
                            />
                        </div>
                        <div style={{ overflow: "hidden", height: 0 }}>
                            <DownloadHistoryFile
                                ref={printFile}
                                data={downloadData}
                                currentTab={currentTab}
                                dataVersionList={versionsState}
                                degreesData={degrees}
                                squintsData={squints}
                            />
                        </div>
                        <div style={{ overflow: "hidden", height: 0 }}>
                            <Suspense>
                                {downloadDataSurgery && (
                                    <SurgeryDownloadFile
                                        ref={printFile}
                                        dataHistory={downloadDataSurgery}
                                        isHistory
                                    />
                                )}
                            </Suspense>
                        </div>
                    </>
                )}
            </div>
            {openDetailsModals?.data?.appId && (
                <HistoryComponent
                    appId={openDetailsModals?.data?.appId}
                    onClose={handleCloseModal}
                    isOpen={openDetailsModals.isOpenAttention}
                    sheet={{
                        name: openDetailsModals?.data?.attentionInfo.name,
                        prefix: openDetailsModals?.data?.attentionInfo.prefix,
                    }}
                    dataVersionList={versionsState}
                    degreesData={degrees}
                    squintsData={squints}
                />
            )}
            <DiagnosticAdxDetails
                configModal={configModalADX}
                setConfigModal={setConfigModalADX}
            />
            <ConfigSegments
                isOpen={configSegmentsModal.isOpen}
                config={configSegmentsModal.config}
                onClose={() => {
                    setConfigSegmentsModal((state) => ({ ...state, isOpen: false }));
                }}
                onSubmit={(values) => {
                    setConfigSegmentsModal({ config: values, isOpen: false });
                }}
            />
        </div>
    );
};

export default GeneralHistory;
