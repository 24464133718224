import { axiosInstance } from "@/config/axios";
import { AccountListResponse, AppointmentListHistoryResponse, DownloadHCXResponse, DownloadHistoryPdfResponse, DownloadHistoryResponse, OriginListSegmentResponse, ParamsDownloadHistory, ParamsListAppHistory, ParamsOriginSegmentHistory, PatientListResponse, SheetSegmentResponse, } from "@/models/generalHistory/generalHistory";
import { setHeaders } from "@/utils";

export async function getListPatientService(params: { orderByType: string; search?: string | number; page: number; perpage: number, id?: number, docFilter?: string }, url = "medical/patient/patients-list"): Promise<PatientListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };
    const { data: serviceData } = await axiosInstance.get<PatientListResponse>(
        url, axiosParams
    );
    return serviceData;
}

export async function getListAccountsService(): Promise<AccountListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { active: 1 } };

    const { data: serviceData } = await axiosInstance.get<AccountListResponse>(
        "/admin/accounts/", axiosParams
    );
    return serviceData;
}

export async function getListAppointmentHistoryService(params: ParamsListAppHistory): Promise<AppointmentListHistoryResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AppointmentListHistoryResponse>(
        "/medical/clinicHistory/attentionHistoryAll/", axiosParams
    );
    return serviceData;
}

export async function getOriginListSegmentService(params: ParamsOriginSegmentHistory): Promise<OriginListSegmentResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OriginListSegmentResponse>(
        "/medical/clinicHistory/attentionSheetSegment/", axiosParams
    );
    return serviceData;
}

export async function getDownloadHistoryService(params: ParamsDownloadHistory): Promise<DownloadHistoryResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DownloadHistoryResponse>(
        "/medical/clinicHistory/attentionHistoryDownloadAll/", axiosParams
    );
    return serviceData;
}

export async function getDownloadHistoryPdfService(params: ParamsDownloadHistory): Promise<DownloadHistoryPdfResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DownloadHistoryPdfResponse>(
        "/medical/clinicHistory/download-attention-history", axiosParams
    );
    return serviceData;
}

export async function getDownloadHCXService(params: { cluId: number, filename: string }): Promise<DownloadHCXResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DownloadHCXResponse>(
        "/medical/clinicHistory/previousMedicalHistory/", axiosParams
    );
    return serviceData;
}

export async function getSheetSegmentsService(): Promise<SheetSegmentResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<SheetSegmentResponse>(
        "/medical/clinicHistory/sheet-segments-for-history", axiosParams
    );
    return serviceData;
}

